/* --- Header text feature --- */
.call-out {
  @extend %padding-header;
  display: inline-block;
  width: 100%;
  height: 100%;
  font-size: 1.2em;
  text-align: center;
  color: var(--header-text);
  background: no-repeat var(--header-background);
  backdrop-filter: brightness(0.5);
  background-size: cover;

  h1 {
    font-family: $font-family-logo;
    @extend .subtle-txt-shadow;
  }

  /* --- Responsive --- */
  @media screen and (max-width: $m-break) {
    background-size: 170% auto;
  }

  @media screen and (max-width: 450px) {
    background-size: 200% auto;
  }

  @media screen and (max-width: 400px) {
    background-size: 250% auto;
  }

  @media screen and (max-width: 380px) {
    background-size: 280% auto;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

#main {
  background-position: 100% -50px, 0 center, center top;
}

/* --- Post listing --- */
.posts {
  .post-teaser {
    @extend %padding-post;
    width: 100%;
    margin-bottom: 0;
    display: inline-block;
    background-size: cover;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    p a {
      @extend .body-link;
    }

    .post-img {
      vertical-align: middle;
      border-radius: 10px;
      overflow: hidden;

      @media (min-width: $break) {
        height: 250px;
      }

      //Smaller screen
      @media screen and (max-width: $break) {
        height: 150px;
      }

      img {
        width: 100%;
        padding: 0;
        vertical-align: middle;
      }

    }

    .post-img img {
      -moz-transition-timing-function: ease-out;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;

      -moz-transition: all 2s;
      -webkit-transition: all 2s;
      transition: all 2s;

      &:hover {
        -moz-transform: scale(1.1);
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }

  .excerpt {
    margin-top: 1em;
  }
}
